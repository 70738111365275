import React from "react"

class PropContext extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    const { children, props } = this.props
    return React.Children.map(children, child =>
      React.cloneElement(child, { ...this.props })
    )
  }
}

export default PropContext
